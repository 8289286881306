.terms-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c9d1d9;
    margin-top: 10svh;
    padding: 0;
}

.terms-content {
    max-width: 1300px;
    padding-inline: 40px;
    border-radius: 8px;
}

.terms-title {
    font-size: 36px;
    margin-bottom: -5px;
    color: #4093ff;
}

.terms-date {
    margin-left: 5px;
    font-size: 14px;
    color: #8b949e;
    margin-bottom: 30px;
}

.terms-section {
    margin-bottom: 50px;
}

.terms-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #f0f6fc;
    display: flex;
    align-items: center;
}

.terms-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #adbac7;
}

.terms-icon {
    margin-right: 10px;
}

a {
    color: #4093ff;
    text-decoration: none;
}

.terms-divider {
    margin-bottom: 3svh;
    border: 1px solid #0a1c34;
}

.terms-access__container {
    border: 2px solid #0a1c34;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.terms-access__container ul {
    color: #adbac7;
}

.terms-access__container li {
    color: #adbac7;
    margin-bottom: 10px; /* Adds 5px gap between list items */
}

/* Optional: Remove margin from the last list item */
.terms-access__container li:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .terms-content {
        padding: 20px;
    }

    .terms-title {
        font-size: 28px;
    }

    .terms-section h2 {
        font-size: 20px;
    }
}