.dashboard__container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 2rem;
}

/* Loader */

.dashboard__loading {
    margin-left: 11rem;
    margin-top: 1rem;
}

.dashboard__loading--goal {
    margin-top: 1rem;
}

/* Recent BMI */

.dashboard__recent {
    display: flex;
    flex-direction: row;
    margin-top: 3rem;
    width: 395px;
    padding: 15px 10px;
    text-align: center;
    color: var(--text-color);
    border-radius: 10px;
    font-size: 18px;
    position: relative;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    justify-content: center;
    align-content: center;
}

/*.dashboard__title {*/
/*    -webkit-backdrop-filter: blur(10px);*/
/*    text-align: left;*/
/*    border: 2px solid #0a1c34;*/
/*    background: none;*/
/*    backdrop-filter: blur(10px);*/
/*    color: var(--text-color);*/
/*    padding: 0 10px;*/
/*    font-size: 18px;*/
/*    border-radius: 5px;*/
/*    z-index: 30;*/
/*    width: auto;*/
/*}*/

/*legend {*/
/*    all: unset;*/
/*    display: inline-block;*/
/*    padding: 0 10px;*/
/*    white-space: nowrap;*/
/*    text-align: left;*/
/*    border: 2px solid #0a1c34;*/
/*    border-radius: 5px;*/
/*    background: rgba(255, 255, 255, 0);*/
/*}*/

fieldset {
    padding: 0;
    margin: 0;
    border: none;
}

legend {
    display: inline-block;
    width: auto;
    white-space: nowrap;
    text-align: left;
    background: rgba(255, 255, 255, 0);
    border: 2px solid #0a1c34;
    border-radius: 5px;
    font-size: 18px;
    color: var(--text-color);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.dashboard__title,
.dashboard__title-goal {
    background: rgba(255, 255, 255, 0);
    text-align: left;
    border: 2px solid #0a1c34;
    color: var(--text-color);
    /*padding: 0 10px;*/
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    border-radius: 5px;
    z-index: 30;
    width: auto;
}

.dashboard__title-goal {
    width: 100px;
}

legend + * {
    -webkit-margin-top-collapse: separate;
}


.dashboard__recent--weight {
    font-size: 18px;
}

.dashboard__fields--container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-top: -1.5rem;
}

.dashboard__title--alt {
    color: #bebdbd;
    font-size: 15px;
    z-index: 31;
}

.dashboard__recent--text {
    text-align: center;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 20px;
}

.dashboard__recent--text.visible {
    margin-top: 18px;
}

.dashboard__recent--bmi,
.dashboard__recent--weight {
    font-weight: 600;
}

.dashboard__recent--weight {
    font-size: 17px;
}

.dashboard__recent--date {
    color: #bebdbd;
    font-size: 17px;
}

/* Goal */

.dashboard__goal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 3rem;
    width: 395px;
    padding: 15px 10px;
    text-align: center;
    color: var(--text-color);
    border-radius: 10px;
    font-size: 20px;
    position: relative;

    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
}

.dashboard__goal.none {
    margin-top: 3rem;
}

.dashboard__goal--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard__goal--text {
    font-size: 16px;
}

.dashboard__goal--span {
    font-weight: 600;
    font-size: 19px;
}

.dashboard__goal--btn {
    background: none;
    width: 130px;
    border: 2px solid #0b51af;
    font-size: 18px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #67a7fd;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.dashboard__goal--btn:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
}

.dashboard__goal--btn:active {
    transform: scale(0.9);
}

.dashboard__button--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

/* Update Goal Pop-up */

.popup__goal {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 395px;
    padding: 15px 10px;
    text-align: center;
    background: linear-gradient(to bottom, #0c437a, #072342);
    color: var(--text-color);
    border-radius: 10px;
    font-size: 20px;
    position: relative;
}


.popup__goal--toggle {
    display: flex;
    align-items: center;
    color: var(--dark-color);
    margin-bottom: 20px;
    margin-top: 20px;
}

.toggle__one {
    display: flex;
    flex-direction: row;
    margin-right: 30px;
}

.toggle__two {
    display: flex;
    flex-direction: row;
}

.popup__fields--input,
.popup__fields--last {
    margin-top: -10px;
    width: 50px;
    height: 35px;
    border-radius: 5px;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    font-size: 18px;
    text-align: center;
}

.popup__fields--last {
    margin-left: 20px;
}

.popup__fields--input.error,
.popup__fields--last.error {
    border: 3px solid var(--error);
}

.popup__field--text {
    margin-left: 20px;
    font-weight: 600;
    color: #d7d7d7;
    font-size: 17px;
    margin-top: 2rem;
}

.popup__goal--buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 5rem;
}

.popup__goal--remove {
    margin-left: 3em;
}

@media screen and (max-width: 395px) {
    .popup__goal--buttons {
        display: flex;
        flex-direction: row;
        gap: 3rem;
    }
}

@media screen and (max-width: 343px) {
    .popup__goal--buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
}

/* Dashboard Trend */

.popup--input {
    margin-top: -10px;
    width: 50px;
    height: 35px;
    border-radius: 5px;
    border: 2px solid #1a406e;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    font-size: 18px;
    text-align: center;
}

.popup--input.error {
    border: 2px solid #b80000;
}

/*----*/

.dashboard__trend {
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    width: 395px;
    padding: 15px 10px;
    color: var(--text-color);
    border-radius: 10px;
    font-size: 20px;
    position: relative;

    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
}

.dashboard__trend--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 23px;
    gap: 8px;
}

.dashboard__trend--icon {
    margin-top: 20px;
    /*margin-right: 10px;*/
}

.dashboard__trend--text {
    font-size: 20px;
    font-weight: 600;
}

@media screen and (max-width: 1900px) {
    .dashboard__container {
        gap: 3rem;
    }
}

@media screen and (max-width: 1630px) {
    .dashboard__container {
        display: grid;
        grid-template-rows: repeat(2, auto);
        grid-template-columns: repeat(2, auto);
        gap: 2rem;
    }
}

@media (max-width: 925px) {
    .dashboard__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 792px) {
    .dashboard__trend,
    .dashboard__recent,
    .dashboard__goal {
        width: 350px;
    }

    .dashboard__recent--weight {
        font-size: 16px;
    }

    .popup__goal {
        width: 340px;
        margin-top: 30px;
    }

    .popup__goal--toggle {
        display: flex;
        flex-direction: column;
    }

    .toggle__one {
        margin-right: auto;
    }

    .toggle__two {
        margin-top: 20px;
        margin-right: auto;
    }

    .dashboard__goal--btn {
        font-size: 16px;
    }
}

@media screen and (max-width: 400px) {
    .dashboard__trend,
    .dashboard__recent,
    .dashboard__goal {
        width: 313px;
    }

    .dashboard__recent--weight,
    .dashboard__recent--text {
        font-size: 18px;
    }

    .popup__goal {
        width: 313px;
        margin-top: 30px;
    }

    .popup__goal--toggle {
        display: flex;
        flex-direction: column;
    }

    .toggle__one {
        margin-right: auto;
    }

    .toggle__two {
        margin-top: 20px;
        margin-right: auto;
    }

    .dashboard__goal--btn {
        font-size: 14px;
        width: 100px;
    }
}

@media screen and (max-width: 345px) {
    .dashboard__trend,
    .dashboard__recent,
    .dashboard__goal {
        width: 250px;
    }

    .dashboard__recent--weight,
    .dashboard__recent--text {
        font-size: 16px;
    }

    .dashboard__recent--date {
        font-size: 14px;
    }

    .popup__goal {
        width: 250px;
        margin-top: 30px;
    }

    .popup__goal--toggle {
        display: flex;
        flex-direction: column;
    }

    .toggle__one {
        margin-right: auto;
    }

    .toggle__two {
        margin-top: 20px;
        margin-right: auto;
    }

    .dashboard__goal--btn {
        font-size: 14px;
        width: 100px;
    }
}