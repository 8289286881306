body {
  background: linear-gradient(to bottom, var(--grad-color-one) 10%, rgba(0, 0, 0, 0.7) 25%, var(--grad-color-two) 100%);
}

.app {
  margin-top: 13svh;
  font-family: 'Noto Sans', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  align-items: center;
  overflow-x: hidden;
  color: var(--text-color);
}

.page__title {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.page__title-text {
  font-size: 32px;
  font-weight: bold;
  color: #dcddde;
  margin-bottom: 20px;
  border-bottom: 2px solid #1a406e;
  padding-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .toggle__text {
    display: flex;
    margin-left: 8px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .toggle__checkbox {
    display: flex;
    margin-top: 28px;
    margin-left: 50px;
  }
}

.toggle__text {
  display: flex;
  margin-left: 10px;
  border: none;
  background: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 20px;
  color: var(--text-color);

}

.toggle__text:hover {
  text-decoration: underline;
}

.toggle__checkbox {
  display: flex;
  margin-top: 28px;
  margin-left: 50px;
  margin-bottom: 23px;
  color: transparent;

  transition: background-color 0.3s ease;
}

.header__image {
  width: 90px;
  height: 90px;
  margin-top: 30px;
  margin-left: 20px;
  display: flex;
}

.header__title {
  display: flex;
  margin-inline: 60px;
  margin-top: 20px;
  text-align: center;
  color: #3a3a3a;
}

.bmiFooter {
  margin-top: 0;
}

.fields, .metric-fields {
  display: flex;
  color: var(--text-color);
}

.fields__input {
  width: 65px;
  height: 40px;
  display: flex;
  border-radius: 10px;
  font-size: 17px;
  color: var(--text-color);
  background-color: var(--dark-color);
  border: none;
}

.fields--height__container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.fields--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fields--errors__container {
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fields--success__container {
  margin-top: 2svh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.success,
.contact_success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 8px;
}

.success span {
  font-weight: 600;
  color: #1aa1ff;
}

.errors {
  font-size: 14px;
  color: #fd4040;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
}

.errors span {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.fields--input {
  margin-top: -10px;
  width: 50px;
  height: 35px;
  border-radius: 5px;
  border: 2px solid #0a1c34;
  background: rgba(10, 28, 52, 0.2);
  backdrop-filter: blur(10px);
  font-size: 18px;
  text-align: center;
}

.fields--input.error {
  border: 2px solid #b80000;
}

.fields__input.error {
  border: 3px solid var(--error);
}

.user--bmi {
  font-size: 18px;
}

.user--bmi span {
  font-weight: 600;
  color: #1aa1ff;
}

.range--container {
  margin-top: 5svh;
  width: 400px;
  border-radius: 20px;
  border: 2px solid #0a1c34;
  background: rgba(10, 28, 52, 0.2);
  backdrop-filter: blur(10px);
  text-align: center;
}

.range--title__container {
  font-weight: bold;
  color: #dcddde;
  padding: 0;
  border-bottom: 2px solid #0a1c34;
}

.range--title {
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.range--button__save.logged_in {
  background: #0b51af;
  width: 350px;
  height: 45px;
  border: 2px solid #0b51af;
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 30px;
  transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.range--button__save:disabled {
  background: transparent;
  width: 350px;
  height: 45px;
  border: 2px solid #0b51af;
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  border-radius: 30px;
}

.range--button__save.saved {
  background: transparent;
  width: 350px;
  height: 45px;
  border: 2px solid #0b51af;
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  border-radius: 30px;
}

.range--button__save.saved:hover,
.range--button__save.saved:active {
  cursor: not-allowed;
}

.range--button__save span {
  gap: 10px;
  display: flex;
  font-size: 20px;
}

.range--button__save.logged_in:hover {
  border: 2px solid #0b3f88;
  color: #216ad2;
  background: transparent;
  box-shadow: 0 0 15px #216ad2, 0 0 25px #337be1, 0 0 35px #0d6aef;
}

.range--button__container {
  margin-bottom: 20px;
  border-top: 2px solid #0a1c34;
  padding-top: 20px;
  /*overflow-y: hidden;*/
}

@media screen and (max-width: 410px) {
  .range--container {
    width: 250px;
  }

  .range--button__save:disabled {
    width: 200px;
    font-size: 16px;
  }

  .range--button__save {
    width: 200px;
    font-size: 16px;
  }

  .range--button__save span {
    font-size: 16px;
  }
}

.confetti__container {
  overflow: hidden;
}

.range--button__save.logged_in:active {
  transform: scale(0.9);
}

.range--info {
  line-height: 30px;
}

.range--title span {
  justify-content: center;
  margin-right: 10px;
}

.range--info {
  font-size: 16px;
}

.results {
  display: flex;
  margin-left: 40px;
  margin-top: 40px;
  font-weight: 800;
  font-size: 30px;
}

.results p {
  color: var(--text-color);
}

.results__text {
  display: flex;
  margin-left: 20px;
  text-decoration: underline;
  color: var(--text-color);
}

.results-title__text {
  font-weight: 600;
  text-decoration: underline;
  font-size: 20px;
  margin-top: -1svh;
}

.information {
  width: 400px;
  height: 220px;
  background-color: var(--information-color);
  border-radius: 20px;
  color: var(--text-color);
}

.information__text {
  color: var(--text-color);
  margin-left: 20px;
}

.info-text__title {
  text-decoration: underline;
  margin-bottom: 10px;
  margin-left: 20px;
}

.bottom-container {
  display: flex;
  flex-direction: row;
  margin-top: 5svh;
}

.weight {
  margin-left: 2rem;
  width: 350px;
  height: 220px;
  background-color: var(--information-color);
  border-radius: 20px;
  color: var(--text-color);
}

.weight__text {
  padding: 20px;
}

.weight__text p {
  color: var(--text-color);
}

.weight__text-link {
  color: var(--link);
  font-weight: 500;
}

.weight__text-link:hover {
  color: var(--link-hover);
  font-weight: 600;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.hidden {
  display: none;
  background-color: white;
}

.source {
  display: none;
}

/* Save Data Button (only when logged in) */

.save-data__container {
  position: relative;
}

.save-data__button {
  width: 40px;
  height: 30px;
  font-size: 30px;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: 0.5s ease;
  margin-top: 28px;
  margin-left: 10px;
}

.save-data__button:hover {
  color: #d5d5d5;
}

.save-data__button:active {
  color: #b7b7b7;
}

/* Save Data Dropdown */

.save-data__dropdown {
  z-index: 2;
  position: absolute;
  text-align: center;
  margin-left: 1rem;
}

.dropdown__button {
  background-color: #052b4d;
  color: var(--text-color);
  width: 90px;
  height: 40px;
  padding: 10px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  z-index: 3;
  position: relative;
  font-weight: 500;
  font-size: 17px;
  animation: dropdown-show 0.3s ease forwards;
 }

.dropdown__button:hover {
  background-color: #031628;
}

.dropdown__button:active {
  transition: 0.5s ease;
  transform: scale(0.9);
}

.dropdown__button:disabled {
  background-color: #2a2a2a;
  color: #808080;
  cursor: not-allowed;
}

.weight__alt-text,
.weight__alt-link {
  display: none;
}

.dropdown__button:disabled:hover,
.dropdown__button:disabled:active {
  background-color: #2a2a2a;
  color: #808080;
  transform: scale(1);
}

.hw-results__container,
.hw-results__container-alt {
  display: flex;
  padding-left: 20px;
  margin-bottom: -2em;
}

.hw-results__container-alt {
  display: none;
}

.hw-results__height {
  font-weight: 600;
  font-size: 16px;
}

.hw-results__weight {
  margin-left: 45px;
  font-weight: 600;
  font-size: 16px;
}

.divider-alt {
  display: none;
}



@media screen and (max-width: 795px) {
  .header__link-img {
    display: none;
  }
}

/* Smaller windows */

@media only screen and (max-width: 850px) {
  .source__img-link {
    width: 30px;
    height: 30px;
  }

  .bottom-container {
    display: flex;
    flex-direction: column;
  }

  .divider-alt {
    display: block;
    width: 98%;
    margin-left: 4px;
    height: 1px;
    background-color: #ccc;
    margin-bottom: 2svh;
  }

  .information {
    height: 320px;
    margin-top: -2rem;
  }

  .hw-results__container-alt {
    margin-top: -1em;
    display: flex;
  }

  .weight {
    display: none;
  }

  .weight__alt-text {
    display: flex;
    margin-left: 20px;
    margin-top: 1.5em;
  }

  .weight__alt-link {
    display: flex;
    color: var(--link);
    font-weight: 500;
    margin-top: -10px;
    margin-left: 20px;
  }

  .weight__alt-link:hover {
    color: var(--link-hover);
    font-weight: 600;
  }

  .fields, .metric-fields {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
  }

  .fields__input {
    margin-left: 15px;
    margin-top: 0.7rem;
    width: 65px;
    height: 40px;
  }
}

@media screen and (max-width: 792px) {
  .toggle {
    display: flex;
    flex-direction: column;
  }

  .toggle__one {
    margin-right: auto;
  }

  .toggle__two {
    margin-top: 20px;
    margin-right: auto;
  }

  .bottom-container {
    margin-top: 10svh;
    width: 320px;
    margin-bottom: 20svh;
  }

  .information {
    width: 100%;
    height: auto;
  }

  .results {
    margin-left: 0;
  }

  .hw-results__container-alt {
    padding: 0;
    flex-direction: column;
    margin-left: 20px;
  }

  .results__container-small {
    margin-bottom: 3svh;
  }

  .hw-results__height {
    margin-bottom: 0;
  }

  .hw-results__height,
  .hw-results__weight {
    font-size: 15px;
    margin-left: 0;
  }

  .fields__input {
    width: 50px;
  }
}
