.main {
    margin-top: 13svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

.main__footer {
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100%;
}

.main__link-container {
    display: inline;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
}

.main__link-a {
    color: #3a3a3a;
    padding-left: 2vw;
}

.errors {
    color: var(--error);
    font-weight: bold;
}

.toggle {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .toggle__text {
        display: flex;
        margin-left: 8px;
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .toggle__checkbox {
        display: flex;
        margin-top: 28px;
        margin-left: 50px;
    }
}

.toggle__text {
    display: flex;
    margin-left: 10px;
}

.results__text-two {
    padding-left: 1vw;
}

.results__info {
    display: flex;
}

.results__info a {
    color: var(--text-color);
}

.results__info-link {
    color: var(--text-color);
    padding-left: 2vw;
    text-decoration: none;
    font-size: 20px;
}

.results__info-span {
    color: var(--text-color);
    text-decoration: underline;
    font-weight: 500;
}

.hidden {
    display: none;
    background-color: white;
}

@media screen and (max-width: 792px) {
    .results {
        font-size: 25px;
    }
}