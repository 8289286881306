.toggle-container {
    display: inline-block;
    font-family: Arial, sans-serif;
    cursor: pointer;
    user-select: none;
    margin-bottom: 3svh;
}

.toggle-button {
    display: flex;
    align-items: center;
    position: relative;
    width: 250px;
    border-radius: 25px;
    background-color: #010710;
    border: 2px solid #1a406e;
    overflow: hidden;
    transition: background 0.3s ease-in-out;
}

.toggle-option {
    flex: 1;
    text-align: center;
    padding: 13px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: color 0.3s ease-in-out;
}

.checkmark {
    font-size: 16px;
    color: #ffffff;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    top: 53%;
    transform: translateY(-50%) scale(0.5);
}

.left-selected .checkmark {
    left: 10px;
}

.right-selected .checkmark {
    right: 10px;
}

.active .checkmark {
    opacity: 1;
    transform: translateY(-50%) scale(1);
}

.toggle-divider {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: 2px;
    background-color: #1a406e;
    z-index: 1;
}

.toggle-slider {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #002b67;
    transition: transform 0.3s ease-in-out, border-radius 0.3s ease-in-out;
    z-index: 0;
    border-radius: 25px;
}

.left-selected .toggle-slider {
    transform: translateX(0);
    border-radius: 25px 0 0 25px;
}

.right-selected .toggle-slider {
    transform: translateX(100%);
    border-radius: 0 25px 25px 0;
}

.active {
    color: var(--text-color);
    position: relative;
    z-index: 1;
}
