* {
    color: rgb(255 255 255 / .9);
}

.nav-bar {
    background: rgba(2, 6, 12, 0.16);
    backdrop-filter: blur(5px);
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    padding: 15px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-bar__icon {
    display: flex;
    align-items: center;
}

.icon {
    width: 42px;
    height: 42px;
    margin-right: 15px;
}

.nav-bar__title {
    font-size: 32px;
    font-weight: bold;
    background: -webkit-linear-gradient(180deg, #9cc6ff, #1c7eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 8px rgba(26, 126, 255, 0.4),
    0 0 12px rgba(26, 126, 255, 0.3),
    0 0 20px rgba(255, 255, 255, 0.2);
}

.nav-bar__links {
    margin-left: 2.5svw;
}

.nav-bar__links a {
    margin-left: 3svw;
    color: rgb(255 255 255 / .9);
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.nav-bar__links a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: rgb(255 255 255 / .9);
    transition: width 0.4s ease;
}

.nav-bar__links a:not(.current):hover::after {
    width: 100%;
}

.nav-bar__links a:not(.current):hover {
    color: rgba(230, 230, 230, 0.9);
    margin-left: 3svw;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
}

.nav-bar__links a.current {
    color: var(--primary-blue-two);
    border-bottom: 3px solid var(--primary-blue-two);
    border-bottom-width: 2px;
}

.nav-bar__links a.current:hover {
    color: var(--button-gradient-start);
    border-bottom: 3px solid var(--button-gradient-start);
    border-bottom-width: 2px;
}

.nav-bar--buttons {
    margin-left: 10svw;
}

.hamburger-button {
    display: none;
}

.nav-bar--account {
    width: 137px;
    margin-left: 30px;
    color: var(--text-color);
    background: linear-gradient(to right, #2170ce, #0b51af);
    padding: 15px 30px;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.2rem;
    border: none;
    cursor: pointer;
    transition: all 500ms ease-in;
}

.nav-bar--account:hover {
    background: linear-gradient(to right, #0a4298, #072c67);
    box-shadow: rgba(72, 135, 202, 0.8) 0 0 40px 13px;
}

.nav-bar--account_logged_in {
    background: none;
    font-size: 4rem;
    margin: 0 0 0 30px;
    padding: 0;
    height: 64px;
    width: 64px;
    border-radius: 100px;
    box-shadow: none;
    transition: filter 500ms ease-in;
    border: none;
    cursor: pointer;
}

.nav-bar--account_logged_in:hover {
    filter: drop-shadow(0 0 13px rgba(72, 135, 202, 0.8));
}

.nav-bar--account_logged_in:active{
    transform: scale(0.9);
}

/* Media Queries */
@media (max-width: 1420px) {
    .nav-bar--buttons__alt {
        display: none;
    }

    .nav-bar--buttons__main {
        width: 100px;
        padding: 0;
        height: 58px;
    }
}

@media (max-width: 1340px) {
    .nav-bar--buttons__alt, .nav-bar--buttons__main {
        width: 100px;
        padding: 0;
        height: 58px;
    }
}

@media (max-width: 1185px) {
    .nav-bar__title {
        font-size: 24px;
    }
}

@media (max-width: 1125px) {
    .nav-bar__links, .nav-bar--buttons__alt, .nav-bar--buttons__main, .nav-bar--account, .nav-bar--account_logged_in {
        display: none;
    }

    .hamburger-button {
        display: flex;
        font-size: 45px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: #cdcdcd;
    }

    .nav-bar--buttons {
        margin-left: 50svw;
    }
}

@media (max-width: 810px) {
    .nav-bar--buttons {
        margin-left: 40svw;
    }
}

@media (max-width: 680px) {
    .nav-bar--buttons {
        margin-left: 30svw;
    }
}

@media (max-width: 575px) {
    .nav-bar--buttons {
        margin-left: 20svw;
    }
}

@media (max-width: 495px) {
    .nav-bar__title {
        font-size: 20px;
    }
}

@media (max-width: 440px) {
    .nav-bar--buttons {
        margin-left: 10svw;
    }
}

@media (max-width: 395px) {
    .nav-bar--buttons {
        margin-left: 3svw;
    }
}

@media (max-width: 395px) {
    .icon {
        display: none;
    }

    .nav-bar--buttons {
        margin-left: 3svw;
    }
}

@media (max-width: 355px) {
    .nav-bar__title {
        margin-left: 0;
    }

    .nav-bar--buttons {
        margin-left: 3svw;
    }
}

@media (max-width: 315px) {
    .nav-bar__title {
        font-size: 16px;
    }
}

@media (max-width: 303px) {
    .nav-bar--buttons {
        font-size: 20px;
    }
}

/* Menu Overlay - Smaller Screens */

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
}

.menu-overlay.engaged {
    display: block;
    z-index: 50;
}

.menu {
    position: fixed;
    top: 0;
    right: -250px;
    width: 250px;
    height: 100%;
    background: #3a3a3a;
    transition: right 0.3s ease;
    z-index: 1000;
    color: #e0e0e0;
}

.menu.engaged {
    right: 0;
}

.menu-header {
    padding: 10px;
    text-align: right;
}

.menu-header__text {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}

.close-button {
    font-size: 40px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    color: #e0e0e0;
}

.menu-links {
    width: 85%;
    height: 100%;
}

.menu-links a {
    display: block;
    padding: 10px;
    width: 100%;
    text-decoration: none;
    color: #e0e0e0;
    font-size: 18px;
}

.account {
    margin-bottom: 20px;
}

.menu-links a.current {
    color: var(--primary-blue-two);
    border-bottom: 3px solid var(--primary-blue-two);
    border-bottom-width: 2px;
}

.menu-links a.current:hover {
    color: var(--button-gradient-start);
    border-bottom: 3px solid var(--button-gradient-start);
    border-bottom-width: 2px;
}

/*------------*/

.nav-bar--account_logged_in {
    background: none;
    font-size: 4rem;
    margin: 0 0 0 30px;
    padding: 0;
    height: 64px;
    width: 64px;
    border-radius: 100px;
    box-shadow: none;
    transition: filter 500ms ease-in;
    border: none;
    cursor: pointer;
}

.nav-bar--account_logged_in:hover {
    filter: drop-shadow(0 0 13px rgba(72, 135, 202, 0.8));
}

/* Dropdown styles */
.account-container {
    position: relative;
    display: inline-block;
}

.dropdown-active a {
    text-decoration: none;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 50%;
    border-radius: 8px;
    width: 180px;
    opacity: 0;
    visibility: hidden;
    transform: translate(-40%, -10px);
    z-index: 6000;

    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);

    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s linear 0.3s;
}

.dropdown-active {
    opacity: 1;
    visibility: visible;
    transform: translate(-40%, 0px);

    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@keyframes fadeInDropdown {
    from {
        opacity: 0;
        transform: translate(-40%, -10px);
    }
    to {
        opacity: 1;
        transform: translate(-40%, 0px);
    }
}

@keyframes fadeOutDropdown {
    from {
        opacity: 1;
        transform: translate(-40%, 0px);
    }
    to {
        opacity: 0;
        transform: translate(-40%, -10px);
    }
}

.dropdown-active {
    animation: fadeInDropdown 0.3s ease forwards;
}

.dropdown:not(.dropdown-active) {
    animation: fadeOutDropdown 0.3s ease forwards;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, visibility 0s linear 0.3s;
    visibility: hidden;
}

.dropdown-button {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    padding: 10px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
}

.dropdown-button:nth-child(1) {
    border-bottom: 2px solid #0a1c34;
}

.dropdown-button:hover {
    background: rgba(0, 0, 0, 0.3);
}

