._404--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-height: 100vh;
}

._404--container h1 {
    font-size: 64px;
    margin-bottom: -1svh;
    color: #f0f6fc;
}

._404--container h2 {
    font-weight: 400;
    color: #8b949e;
}