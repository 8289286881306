.buttons {
    display: flex;
    margin-top: 3svh;
    gap: 30px;
}

.buttons__submit {
    background: #0b51af;
    width: 130px;
    border: 2px solid #0b51af;
    color: var(--text-color);
    font-weight: 500;
    font-size: 18px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.buttons__submit:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
    background: transparent;
    box-shadow: 0 0 15px #216ad2, 0 0 25px #337be1, 0 0 35px #0d6aef;
}

.buttons__submit:active {
    transform: scale(0.9);
}

.buttons__reset {
    background: none;
    width: 130px;
    border: 2px solid #0b51af;
    font-size: 18px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #67a7fd;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.buttons__reset:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
}

.buttons__reset:active {
    transform: scale(0.9);
}

@media screen and (max-width: 328px) {
    .buttons {
        flex-direction: column;
        gap: 5px;
    }

    .buttons__reset, .buttons__submit {
        width: 254px;
    }
}

@media screen and (max-width: 258px) {
    .buttons__reset, .buttons__submit {
        width: auto;
    }
}




