.authUser__container {
    display: flex;
    margin-top: 3svh;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.authUser__text-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.authUser__container-main {
    display: flex;
    flex-direction: row;
}

.auth-text {
    font-weight: 600;
    font-size: 25px;
    display: flex;
    margin-top: 4.5svh;
}

.auth-text__image {
    font-size: 50px;
    margin-right: 20px;
}

.auth-text__email {
    font-weight: 600;
    color: var(--text-color);
    margin-top: 40px;
    text-decoration: none;
    font-size: 17px;
}

.manageUser__container {
    display: flex;
    flex-direction: row;
    margin: 0;
}

.manageUser__error {
    font-size: 25px;
    font-weight: 600;
    color: #cb4141;
}

.manageUser-text__email {
    padding: 0;
    margin: 0;
}

.email {
    margin-left: 10px;
}

.showDisplayName {
    margin-left: 10px;
    font-weight: 600;
}

.manageUser-text__display {
    color: var(--text-color);
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-left: 15px;
}

.display {
    margin-left: 5px;
    text-decoration: underline;
    font-weight: 600;
}

.hidden {
    display: none;
}

@media screen and (max-width: 792px) {
    .manageUser__error {
        width: 300px;
        font-size: 20px;
    }
}