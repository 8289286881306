.main {
    margin-top: 13svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

.main__footer {
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100%;
}

.main__link-container {
    display: inline;
    flex-direction: column;
    text-align: center;
    margin-top: 40px;
}

.main__link-a {
    color: #3a3a3a;
    padding-left: 2vw;
}

.errors {
    color: var(--error);
    font-weight: bold;
}

.toggle {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .toggle__text {
        display: flex;
        margin-left: 8px;
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .toggle__checkbox {
        display: flex;
        margin-top: 28px;
        margin-left: 50px;
    }
}

.toggle__text {
    display: flex;
    margin-left: 10px;
}

.results__text-two {
    padding-left: 1vw;
}

.results__info {
    display: flex;
}

.results__info-link {
    color: #3a3a3a;
    padding-left: 2vw;
    text-decoration: none;
    font-size: 20px;
}

.results__info-span {
    text-decoration: underline;
    font-weight: 500;
}

.hidden {
    display: none;
    background-color: white;
}

.results--container {
    margin-top: 5svh;
    width: 400px;
    border-radius: 20px;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    text-align: center;
}

.results--title__container {
    font-weight: bold;
    color: #dcddde;
    padding: 0;
}

.results--title {
    display: flex;
    justify-content: center;
    font-size: 20px;
}

.results--title span {
    color: #1aa1ff;
    margin-left: 8px;
}

.results__button {
    background: #0b51af;
    width: 350px;
    height: 45px;
    border: 2px solid #0b51af;
    color: var(--text-color);
    font-weight: 500;
    font-size: 18px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 30px;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.results__button span {
    gap: 10px;
    display: flex;
}

.results__button:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
    background: transparent;
    box-shadow: 0 0 15px #216ad2, 0 0 25px #337be1, 0 0 35px #0d6aef;
}

.results--button__container {
    margin-bottom: 20px;
    border-top: 2px solid #0a1c34;
    padding-top: 20px;
}

.confetti__container {
    overflow: hidden;
}

.results__button:active {
    transform: scale(0.9);
}

.results--info {
    line-height: 30px;
}

.results--title span {
    justify-content: center;
    margin-right: 10px;
}

.results--info {
    font-size: 16px;
}

@media screen and (max-width: 450px) {
    .results--container {
        width: 300px;
    }

    .results__button {
        width: 250px;
    }
}

@media screen and (max-width: 310px) {
    .results--title {
        font-size: 18px;
    }

    .results--container {
        width: 250px;
    }

    .results__button {
        width: 200px;
    }
}