.main {
    margin-top: 13svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

.title-container {
    display: flex;
    flex-direction: row;
}

.page__title-text {
    font-size: 32px;
    font-weight: bold;
    color: #dcddde;
    margin-bottom: 20px;
    border-bottom: 2px solid #1a406e;
}

.overlay {
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.29);
    z-index: 30;
}

.title__text {
    display: flex;
    flex-direction: row;
}

.body__container {
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    min-height: 80%;
    width: 94%;
    border-radius: 30px;
    align-content: center;
    text-align: center;
    overflow-y: auto;
    overflow-x: auto;
}

.input__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.input__container--title {
    text-align: left;
    width: 424px;
}

.data__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem;
}

.chart-container {
    margin-top: 2rem;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    height: 500px;
    width: 94%;
    border-radius: 30px;
    align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1000px) {
    .chart-container {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.chart {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    min-height: 120px;
    width: 100%;
    height: 100%;
    max-width: 90%;
    max-height: 90%;
}

.data__height-text {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: -10px;
}

.data__weight-text {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: -10px;
}

.data__range-text {
    font-weight: 600;
    font-size: 16px;
}

.data__information {
    background-color: rgba(1, 7, 16, 0.5);
    border: 2px solid #1a406e;
    padding: 10px;
    border-radius: 10px;
    color: var(--text-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1795px) {
    .data__information {
        flex: 0 0 calc(15% - 1.5rem);
    }
}

@media (max-width: 1672px) {
    .data__information {
        flex: 0 0 calc(20% - 1.5rem);
    }
}

@media (max-width: 1055px) {
    .data__information {
        flex: 0 0 calc(30% - 1.5rem);
    }
}

@media (max-width: 479px) {
    .data__information {
        flex: 0 0 100%;
    }
}

.data__imperial-container,
.data__metric-container,
.data__range-container {
    display: flex;
    flex-direction: column;
}

.data__information-delete {
    background-color: #0b51af;
    border: 2px solid #0b51af;
    padding: 5px;
    color: var(--text-color);
    font-size: 30px;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.data__information-delete:hover {
    background-color: transparent;
    border: 2px solid #0b51af;
}

.data__information-delete:active {
    transform: scale(0.9);
}

.data__error {
    margin-top: 1em;
    margin-left: 2em;
    font-weight: 600;
    font-size: 30px;
}

.edit__displayName {
    margin-top: 5svh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 8svh;
}

.display-name__edit {
    background: none;
    width: 100px;
    border: 2px solid #0b51af;
    font-size: 16px;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #67a7fd;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.display-name__edit:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
}

.display-name__edit:active {
    transform: scale(0.9);
}

@media screen and (max-width: 792px) {
    .body__container {
        width: 330px;
    }

    .data__information {
        flex: 0 0 60%;
        margin-left: 1rem;
    }

    .personal__information {
        margin-bottom: 10em;
    }

    .edit__displayName {
        flex-direction: column;
        margin-bottom: 10svh;
    }

    .display-name__edit {

    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    border: 2px solid #0a1c34;
    backdrop-filter: blur(15px);
    background: rgba(10, 28, 52, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-input {
    width: 90%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    font-size: 18px;
}

.modal-submit-button, .modal-close-button {
    margin-top: 20px;
    background: none;
    width: 100px;
    border: 2px solid #0b51af;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    color: #67a7fd;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.modal-content__buttons {
    display: flex;
    flex-direction: row;
    gap: 11rem;
}

@media screen and (max-width: 435px) {
    .modal-content__buttons {
        display: flex;
        flex-direction: row;
        gap: 8rem;
    }
}

@media screen and (max-width: 375px) {
    .modal-content__buttons {
        display: flex;
        flex-direction: row;
        gap: 5rem;
    }
}

@media screen and (max-width: 310px) {
    .modal-content__buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.modal-submit-button:hover,
.modal-close-button:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
}

.modal-submit-button:active,
.modal-close-button:active {
    transform: scale(0.9);
}

.personal__information {
    margin-bottom: 4rem;
    margin-top: 2rem;
}

.sign-out__button {
    color: var(--text-color);
    font-size: 20px;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #0b51af;
    border: 2px solid #0b51af;
    transition: all 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-out__button:hover {
    background-color: transparent;
    border: 2px solid #0b51af;
    color: #216ad2;
}

.sign-out__button:active {
    transform: scale(0.9);
}

.hidden {
    display: none;
}

@media screen and (max-width: 400px) {
    .body__container {
        width: 280px;
    }

    .data__information {
        flex: 0 0 80%;
    }
}