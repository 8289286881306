.healthmate__login--body {
    margin-top: 13svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

body {
    color: var(--text-color);
    background: linear-gradient(to bottom, var(--grad-color-one), var(--grad-color-two));
}

/* Title */

.healthmate__login--title {
    font-size: 38px;
    border-bottom: 2px solid #1a406e;
    color: var(--text-color);
}

.healthmate__login--title span {
    font-size: 40px;
    font-weight: 800;
    background: -webkit-linear-gradient(180deg, #9cc6ff, #1c7eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.healthmate__login--memo {
    color: var(--text-color);
    font-size: 21px;
    margin-top: -20px;
}

.healthmate__login--memo span {
    font-weight: 600;
    color: var(--text-color);
}

/* Divider */

.healthmate__login--divider {
    display: block;
    width: 90%;
    height: 2px;
    background-color: #0a1c34;
    margin-top: 4svh;
    margin-bottom: 4svh;
    opacity: 50%;
}

.healthmate__divider--text {
    margin: 0 10px;
    color: #999;
    font-weight: bold;
}

/* External Login */
.healthmate__login--alternative {
    margin-top: 1.5svh;
    display: flex;
    flex-direction: row;
    gap: 8svh;
}

.healthmate__login--alternative button {
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    transition: all 500ms;
}

.healthmate__login--alternative button:hover {
    border: 2px solid transparent;
    filter: drop-shadow(0 0 20px rgba(72, 135, 202, 0.8));
}

.healthmate__login--alternative button:active {
    transform: scale(0.9);
}

.healthmate__login--alternative button:disabled {
    cursor: not-allowed;
    background-color: #2a2a2a;
    transform: scale(1);
}

.healthmate__login--alternative button:hover:active:disabled {
    background-color: #2a2a2a;
    transform: scale(1);
}

/* Input Details */

.healthmate__login--information {
    display: flex;
    flex-direction: column;
}

.healthmate__login--input,
.healthmate__login--password {
    margin-bottom: 20px;
    width: 400px;
    height: 35px;
    border-radius: 10px;
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    backdrop-filter: blur(10px);
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

/* Password */

.healthmate__password--container {
    position: relative;
}

.healthmate__password--visible {
    background-color: transparent;
    color: #b7b7b7;
    border: none;
    border-radius: 50px;
    font-size: 25px;
    position: absolute;
    right: 10px;
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Login Button */

.healthmate__login--button {
    background: #0b51af;
    width: 424px;
    margin-top: 20px;
    border: 2px solid #0b51af;
    color: var(--text-color);
    font-weight: 500;
    font-size: 20px;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 10px;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
}

.healthmate__login--button:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
    background: transparent;
    box-shadow: 0 0 15px #216ad2, 0 0 25px #337be1, 0 0 35px #0d6aef;
}

.healthmate__login--button:active {
    transform: scale(0.9);
}

.healthmate__login--button:disabled,
.healthmate__login--button:disabled:hover,
.healthmate__login--button:disabled:active {
    background: none !important;
    border: 2px solid #0b51af !important;
    font-size: 18px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #67a7fd !important;
    cursor: not-allowed !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    transform: none !important;
}

/* Support */

.healthmate__login--support {
    margin-top: 5svh;
    display: flex;
    flex-direction: column;
}

.healthmate__login--support a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 18px;
    margin-bottom: 10px;
}

.healthmate__login--support span {
    margin-left: 10px;
    text-decoration: none;
    font-weight: 600;
    color: #1aa1ff;
}

.healthmate__login--links span {
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.healthmate__login--links span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4093ff;
    transition: width 0.4s ease;
}

.healthmate__login--links span:hover::after {
    width: 100%;
}

.healthmate__login--links span:hover::after {
    width: 100%;
}

.healthmate__login--links span::after {
    transition: width 0.4s ease-out;
}

/* Error */

.healthmate__error--container-none {
    display: none;
}

.healthmate__error--container {
    display: flex;
    flex-direction: column;
}

.healthmate__error--container p {
    color: var(--error);
    margin-bottom: 4svh;
    margin-top: -2svh;
}

@media screen and (max-width: 633px) {
    .healthmate__login--title {
        font-size: 30px;
    }

    .healthmate__login--title span {
        font-size: 32px;
    }

    .healthmate__login--memo {
        font-size: 18px;
    }
}

@media screen and (max-width: 500px) {
    .healthmate__login--input,
    .healthmate__login--password {
        width: 300px;
    }

    .healthmate__login--button {
        width: 324px;
    }

    .fields--success__container {
        display: flex;
    }

    .success {
        display: flex;
        flex-direction: column;
    }

    .success_check {
        display: none;
    }

    .healthmate__login--memo {
        width: 90%;
    }

    .healthmate__login--divider  {
        width: 80%;
    }
}

@media screen and (max-width: 430px) {
    .healthmate__login--input,
    .healthmate__login--password {
        width: 250px;
    }

    .healthmate__login--button {
        width: 274px;
    }

    .healthmate__login--alternative {
        flex-direction: column;
        gap: 3svh;
    }

    .healthmate__login--links {
        display: flex;
        flex-direction: column;
    }
}