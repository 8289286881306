body {
    background: linear-gradient(to bottom, var(--grad-color-one) 10%, rgba(0, 0, 0, 0.7) 25%, var(--grad-color-two) 100%);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #00040c;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #1c7eff;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #2170ce;
}

.home {
    margin-top: 15svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

.cookie-popup {
    backdrop-filter: blur(15px);
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border: 2px solid #0a1c34;
    background: rgba(10, 28, 52, 0.2);
    border-radius: 10px;
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cookie-popup-content span {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
}

.cookie-popup__text {
    color: var(--text-color);
    font-size: 18px;
    padding: 0;
    margin: 15px 0 20px;
}

.cookie-popup p {
  color: #a1a1a1;
  font-size: 18px;
}

.cookie-popup__button {
    background: #0b51af;
    width: 250px;
    border: 2px solid #0b51af;
    color: var(--text-color);
    font-weight: 500;
    font-size: 19px;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
    transition: background 500ms, color 500ms, box-shadow 500ms, border 500ms;
    margin-bottom: 15px;
}

.cookie-popup__button:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
    background: transparent;
    box-shadow: 0 0 15px #216ad2, 0 0 25px #337be1, 0 0 35px #0d6aef;
}

.cookie-popup__button:active {
    transform: scale(0.9);
}

.cookie-popup-domain {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border: 2px solid #0a1c34;
    backdrop-filter: blur(5px);
    background: rgba(10, 28, 52, 0.2);
    border-radius: 10px;
    width: 90%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 6000;
}

.cookie-popup-content--domain {
    text-align: center;
}

.cookie-popup-domain p {
    color: var(--text-color);
    font-size: 18px;
}

.cookie-popup-content--domain a {
    font-weight: 600;
    color: var(--text-color);
}

.cookie-popup-domain button {
    width: 250px;
    border: 2px solid #0b51af;
    padding: 10px 20px;
    background: #0b51af;
    color: white;
    font-size: 19px;
    border-radius: 10px;
    cursor: pointer;
    transition: background 500ms, color 500ms, border 500ms;
}

.cookie-popup-domain button:hover {
    border: 2px solid #0b3f88;
    color: #216ad2;
    background: transparent;
}

.cookie-popup-domain button:active {
    transform: scale(0.9)
}
