.main-page {
    margin-top: 13svh;
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    overflow-x: hidden;
    color: var(--text-color);
}

body {
    background: linear-gradient(to bottom, var(--grad-color-one) 10%, rgba(0, 0, 0, 0.7) 25%, var(--grad-color-two) 100%);
}

.reset-page__information {
    width: 450px;
    height: 100px;
    text-align: center;
    margin-top: 30px;
}


.reset-page__information-span {
    font-size: 20px;
    font-weight: 600;
}

.reset-page__container {
    display: flex;
    align-content: center;
    flex-direction: column;
    text-align: center;
}

.reset-success {
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
}

.reset-page__title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 2%;
    color: var(--text-color);
}

.reset-page__input,
.reset-page__password {
    margin-bottom: 20px;
    background-color: var(--dark-color);
    color: var(--text-color);
    border: none;
    width: 400px;
    height: 40px;
    border-radius: 10px;
    padding-left: 20px;
}

.reset-page__password-container {
    position: relative;
}

.reset-page__button {
    background-color: #BDE3FF;
    border: none;
    color: #3a3a3a;
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    align-content: center;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    width: 100px;
    height: 35px;
    margin-left: 40%;
}

.reset-errors {
    display: flex;
}

.reset-errors__text {
    color: #cb4141;
    font-weight: 600;
    font-size: 17px;
}

.reset-page__button {
    background-color: var(--button-background);
    border: none;
    color: var(--text-color);
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    align-content: center;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    width: 100px;
    height: 40px;
    margin-left: 40%;
}

.reset-page__button:hover {
    transition: 0.5s;
    transform: scale(1.05);
}

.reset-page__button:active {
    transition: 0.3s;
    transform: scale(0.9);
}

.reset-page__button:disabled {
    background-color: #2a2a2a;
    color: #808080;
    cursor: not-allowed;
}

.reset-page__button:disabled:hover,
.reset-page__button:disabled:active {
    background-color: #2a2a2a;
    color: #808080;
    transform: scale(1);
}

.reset-page__signup {
    text-decoration: none;
    border: none;
    background-color: transparent;
    margin-top: 30px;
    font-size: 18px;
    cursor: pointer;
    color: var(--text-color);
}

.signup-text {
    text-decoration: underline;
    margin-left: 15px;
    font-weight: 500;
    color: var(--text-color);
}

.hidden {
    display: none;
}

.reset-page__return {
    font-size: 18px;
    margin-top: 4svh;
    background: none;
    border: none;
    cursor: pointer;
}

.reset-page__return span {
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
    margin-left: 10px;
    font-weight: 600;
    color: #1aa1ff;
}

.reset-page__return span::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4093ff;
    transition: width 0.4s ease;
}

.reset-page__return span:hover::after {
    width: 100%;
}

.reset-page__return span:hover::after {
    width: 100%;
}

.reset-page__return span::after {
    transition: width 0.4s ease-out;
}


@media screen and (max-width: 792px) {
    .reset-page__input {
        width: 320px;
        font-size: 17px;
    }

    .reset-page__button {
        margin-left: 37%;
    }
}