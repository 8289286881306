.signup-page {
    font-family: 'Noto Sans', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    color: var(--text-color);
    background: linear-gradient(to bottom, var(--grad-color-one), var(--grad-color-two));
}

.signup-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.signup-page__information {
    width: 450px;
    height: 100px;
    text-align: center;
    margin-top: 30px;
}

.signup-page__information p {
    color: var(--text-color);
}

.signup-page__information-span {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-color);
}

.signup-page__container {
    display: flex;
    align-content: center;
    flex-direction: column;
    margin-top: 10vh;
}

.signup-page__title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 30%;
    color: var(--text-color);
}

.signup-page__input,
.signup-page__password {
    margin-bottom: 20px;
    background-color: var(--dark-color);
    color: var(--text-color);
    border: none;
    width: 400px;
    height: 40px;
    border-radius: 10px;
    padding-left: 20px;
}

.signup-page__password-container {
    position: relative;
}

.signup-page__password-visible {
    background-color: transparent;
    color: #b7b7b7;
    border: none;
    border-radius: 50px;
    font-size: 25px;
    position: absolute;
    right: 10px; /* Adjust the right value as needed */
    top: 38%;
    transform: translateY(-50%);
    cursor: pointer;
}

.signup-page__button {
    background-color: var(--button-background);
    color: var(--text-color);
    border: none;
    padding: 10px 25px;
    text-decoration: none;
    display: inline-block;
    align-content: center;
    font-size: 16px;
    border-radius: 50px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.5s;
    width: 180px;
    height: 38px;
    margin-left: 30%;
    white-space: nowrap;
}

.signup-page__button:hover {
    transition: 0.5s;
    transform: scale(1.05);
}

.signup-page__button:active {
    transition: 0.3s;
    transform: scale(0.9);
}

.signup-page__button:disabled {
    background-color: #2a2a2a;
    color: #808080;
    cursor: not-allowed;
}

.signup-page__button:disabled:hover,
.signup-page__button:disabled:active {
    background-color: #2a2a2a;
    color: #808080;
    transform: scale(1);
}

.login-errors {
    display: flex;
}

.login-errors__text {
    color: #cb4141;
    font-weight: 600;
    font-size: 17px;
}

.signup-page__signup {
    text-decoration: none;
    color: var(--text-color);
    margin-top: 50px;
    margin-left: 20%;
    font-size: 16px;
}

.signup-text {
    text-decoration: underline;
    margin-left: 15px;
    font-weight: 500;
}

@media screen and (max-width: 792px) {
    .signup-page__information {
        width: 85%;
        height: auto;
    }

    .signup-page__container {
        margin-top: 5svh;
    }

    .signup-page__input {
        width: 320px;
        font-size: 17px;
    }

    .signup-page__password {
        width: 320px;
        font-size: 17px;
    }

    .signup-page__title {
        margin-left: 20%;
    }

    .signup-page__button {
        margin-left: 27%;
    }

    .signup-page__signup {
        margin: 30px 0 0 40px;
        font-size: 17px;
    }
}