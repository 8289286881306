body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

:root {
  --primary-blue-light: #9cc6ff;
  --primary-blue-two: #4697ff;
  --primary-blue: #1c7eff;
  --button-gradient-start: #2170ce;
  --button-gradient-end: #0b51af;
  --button-gradient-hover-start: #0a4298;
  --button-gradient-hover-end: #072c67;
  --button-box-shadow: rgba(72, 135, 202, 0.8);
  --border-blue: #0b51af;
  --border-hover-blue: #0b3f88;
  --text-blue-light: #67a7fd;
  --text-blue-hover: #216ad2;
  --grid-border: #2170ce;
  --grid-bg: rgba(28, 28, 28, 0.66);
  --grid-title: #8abfff;
  --text-grey: #a1a1a1;
  --divider-grey: #ccc;

  --dark-color: #3a3a3a;
  --text-color: #dcddde;
  --grad-color-one: #061225;
  --grad-color-two: #02060c;
  --button-background: #0d2748;
  --link: #71abff;
  --link-hover: #137bfd;
  --error: #dc4a4a;
  --information-color: rgba(15, 43, 84, 0.34);

}
