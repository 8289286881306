.footer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10svh;
}

.footer-info {
    margin-top: 8svh;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15svh;
}

.footer-text {
    margin-right: 9svw;
    color: #4093ff;
}

.status-text, .status-text__a.Online {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #4ade80;
    text-decoration: none;
}

.status-text, .status-text__a.Offline {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #bf3e3e;
    text-decoration: none;
}

.status-text a:hover {
    cursor: pointer;
}

.status-text span {
    margin-top: 2px;
    font-size: 20px;
    margin-right: 5px;
}

.footer-title__container {
    display: flex;
    align-items: center;
}

.icon {
    width: 42px;
    height: 42px;
    margin-right: 15px;
    /*filter: invert(100%) sepia(10%) saturate(150%) hue-rotate(180deg) brightness(120%) contrast(90%);*/
}

.logo__text {
    font-size: 20px;
    font-weight: bold;
    background: -webkit-linear-gradient(180deg, #9cc6ff, #1c7eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 8px rgba(28, 126, 255, 0.4),
    0 0 12px rgba(156, 198, 255, 0.3),
    0 0 20px rgba(255, 255, 255, 0.2);
}

.footer-about {
    margin-left: 10svh;
    margin-right: 20px;
}

/*a5a5a5*/

.footer-about h3 {
    color: #4093ff;
    text-align: left;
}

.footer-about__links a {
    color: #4093ff;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.footer-about__links a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #4093ff;
    transition: width 0.4s ease;
}

.footer-about__links a:hover::after {
    width: 100%;
}

.footer-about__links a:hover::after {
    width: 100%;
}

.footer-about__links a::after {
    transition: width 0.4s ease-out;
}

.footer-about__links {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}

.footer-legal {
    margin-left: 20px;
    text-align: left;
}

.footer-legal h3 {
    color: #cdcdcd;
}

.footer-legal__links {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
}

.footer-legal__links a {
    color: #a5a5a5;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.footer-legal__links a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #a5a5a5;
    transition: width 0.4s ease;
}

.footer-legal__links a:hover::after {
    width: 100%;
}

.footer-legal__links a:hover::after {
    width: 100%;
}

.footer-legal__links a::after {
    transition: width 0.4s ease-out;
}

.footer-legal__links {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.footer-main__container {
    display: flex;
}

/* Media Queries */
@media (max-width: 660px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-main__container {
        display: flex;
    }
}

@media (max-width: 440px) {
    .footer-about {
        margin-left: 0;
    }

    .footer-text {
        margin: 0;
        text-align: center;
    }
}

@media (max-width: 290px) {
    .footer-main__container {
        flex-direction: column;
    }

    .footer-legal {
        margin: 0;
    }
}

@media (max-width: 265px) {
    .footer-text {
        font-size: 12px;
    }

    .status-text {
        display: none;
    }
}

@media (max-width: 537px) {
    .footer-info {
        flex-direction: column;
        align-items: center;
    }

    .footer-text {
        margin-right: 0;
    }
}

